var $nav = $('header');
var scrollAmount = 0;
var controller = new ScrollMagic.Controller();
var windowHeightHalf = $(window).height() / 2;
var sliderSection;
var wheelSection;
var statSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  draggable: true,
  mobileFirst: true,
  centerMode: true,
  centerPadding: '20%',
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        centerPadding: '10%',
      }
    },
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};
var packagesSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  draggable: true,
  mobileFirst: true,
  centerMode: true,
  centerPadding: '5%',
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        centerPadding: '10%',
      }
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '20%',
      }
    },
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};
var relatedSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnFocus: false,
  pauseOnHover: true,
  dots: false,
  infinite: true,
  arrows: false,
  draggable: true,
  mobileFirst: true,
  centerMode: true,
  centerPadding: '5%',
  responsive: [
    {
      breakpoint: 400,
      settings: {
        centerPadding: '10%',
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '15%',
      }
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '20%',
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        centerPadding: '20px',
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0px',
        arrows: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
      }
    },
  ]
};
var productSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  draggable: true,
  initialSlide: 0,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};
var productSliderNavSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-container .slider',
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  autoplay: true,
  mobileFirst: true,
  arrows: true,
  prevArrow: $('.slick-prev'),
  nextArrow: $('.slick-next'),
  responsive: [
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};
var wheelSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  draggable: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};
var wheelSliderNavSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.wheel-slider',
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  autoplay: true,
  mobileFirst: true,
  arrows: true,
  prevArrow: $('.slick-prev'),
  nextArrow: $('.slick-next'),
  responsive: [
    {
      breakpoint: 992,
      settings: "unslick"
    }
  ]
};

function validateForm() {
  var valid = true;
  $('.input-field').each(function() {
    if ($(this).find('input').prop('required')) {
      var response = validateField($(this).find('input'));
      if (!response) {
        valid = false;
      }
    }
  });

  return valid;
}

function validateField(value) {
  var minLength = 1,
    error = false,
    emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  switch ($(value).attr('name')) {
    case 'firstname':
    case 'lastname':
    case 'number':
      if ($(value).val().length >= minLength) {
        error = true;
      }
      break;
    case 'email':
      if (emailReg.test($(value).val())) {
        error = true;
      }
      break;
  }

  return error;
}

function submitForm(form) {
  var data = form.serializeArray();

  jQuery.ajax({
    type: "post",
    url: "email.php",
    data: { data: data },
    dataType: "json",
    success: function(response) {
      if (response['status'] === 'success') {
        $('#contact-form').css({ 'opacity': 0 });
        $('.form-response').html('Thanks for your enquiry.<br />We\'ll get back to you shortly.').addClass('active');
      } else {
        $('.form-error').html('Unable to submit enquiry. Please make sure the fields are populated correctly before sending.').addClass('active');
      }
    }
  });
}

var Graph = (function() {
  function Graph() {
    this.count = 0;
    this.graphEl = $('#graph');
    this.graph = 'oxygen';
    this.animating = false;
    this.clockStart = $('.hours').attr('data-start');
    this.percentStart;
    this.graphClockCounterInterval;
    this.graphCountDownInterval;
    this.graphPercentInterval;
    this.currentGraphObj;
    this.graphData = [
      {
        graphName: 'oxygen',
        graphPoints: [21, 21, 20.5, 20.5, 20.5, 20, 20, 19.5],
        graphLineEl: $('#oxygen-results'),
        percentStart: '21.0'
      },
      {
        graphName: 'methane',
        graphPoints: [3, 3.5, 3.5, 4, 4, 6, 8, 10],
        graphLineEl: $('#methane-results'),
        percentStart: '3.0'
      }
    ];
  }

  Graph.prototype.init = function() {
    if (!this.animating && this.count == 0) {
      this.setupGraphData();
      this.animate();
    }
  };

  Graph.prototype.setupGraphData = function() {
    this.currentGraphObj = this.graphData.find(obj => {
      return obj.graphName === this.graph
    });
    this.percentStart = this.currentGraphObj['percentStart'];
  };

  Graph.prototype.animate = function() {
    this.animating = true;
    $(this.currentGraphObj['graphLineEl']).addClass('animate');
    this.animateClock();
    this.animatePercent();
    this.graphCountDownInterval = setTimeout(this.showResults.bind(this), 4000);
  };

  Graph.prototype.animateClock = function() {
    this.graphClockCounterInterval = setInterval(function() {
      $('.time-counter .hours').text(parseInt($('.time-counter .hours').html()) + 1);
    }, 500);
  };

  Graph.prototype.animatePercent = function() {
    var percentCount = 0;
    var _this = this;

    function percentCounterAnimation() {
      $('.percentage').prop('Counter', _this.currentGraphObj['graphPoints'][0]).animate({
        Counter: _this.currentGraphObj['graphPoints'][percentCount]
      }, {
        duration: 500,
        easing: 'linear',
        step: function(now) {
          $('.percentage').text((Math.round(now * 10) / 10).toFixed(1));
        }
      });
      percentCount++;
    }
    percentCounterAnimation();
    this.graphPercentInterval = setInterval(percentCounterAnimation, 500);
  };

  Graph.prototype.showResults = function() {
    this.stopAnimating();
    $('.graph, .graph-popup, .' + this.currentGraphObj['graphName']).addClass('active');
  };

  Graph.prototype.hideResults = function() {
    $('.graph-popup, .graph, .' + this.currentGraphObj['graphName']).removeClass('active');
  };

  Graph.prototype.resetGraph = function() {
    $(this.currentGraphObj['graphLineEl']).removeClass('animate animate-pause');
    $('.percentage').text(this.percentStart);
    $('.time-counter .hours').text(this.clockStart);
  };

  Graph.prototype.stopAnimating = function() {
    this.count++;
    this.animating = false;
    $(this.currentGraphObj['graphLineEl']).addClass('animate-pause');
    clearInterval(this.graphClockCounterInterval);
    clearInterval(this.graphPercentInterval);
    clearTimeout(this.graphCountDownInterval);
  };

  Graph.prototype.updateGraph = function(nextGraph) {
    this.graph = nextGraph;
    this.setupGraphData();
    $('.graphs').find('div').removeClass('active');
    $('.graphs').find('.' + nextGraph + '-graph').addClass('active');
  };

  Graph.prototype.restart = function() {
    this.hideResults();
    this.resetGraph();
    setTimeout(this.animate.bind(this), 500);
  };

  Graph.prototype.changeGraphs = function(newGraph) {
    if (this.animating) {
      this.stopAnimating();
    }
    if (this.count > 0) {
      this.hideResults();
      this.resetGraph();
    }
    this.updateGraph(newGraph);
    setTimeout(this.animate.bind(this), 500);
  };

  return Graph;
})();

var Wheel = (function() {
  function Wheel() {
    this.currentSlide = 0;
    this.currentSlideData;
    this.currentScrollPos;
    this.sliderEl = $('.wheel-slider');
    this.slides = [];
    this.svg = $('.svg-container svg');
  }

  Wheel.prototype.init = function() {
    this.getSlideData();
    this.currentSlideData = this.getCurrentSlideData(this.currentSlide);
    this.currentScrollPos = ($('#product-wheel').height() / 2) - ($('.wheel-slider .slide:first').outerHeight(true) / 2);
    this.setInitialScrollPos();

  };

  Wheel.prototype.setInitialScrollPos = function() {
    $('.wheel-slider').css({ 'transform': 'translateY(' + this.currentScrollPos + 'px)' });
  };

  Wheel.prototype.getSlideData = function() {
    var _this = this;
    this.sliderEl.find('.slide').each(function(index) {
      _this.slides.push({
        el: $(this),
        slide: index,
        height: $(this).outerHeight(true),
        svgColor: $('.svg-container svg #enabled #enabled' + index + ' [data-name=base]').css('fill')
      });
    });
  };

  Wheel.prototype.getCurrentSlideData = function(slide) {
    return this.slides.find(obj => {
      return obj.slide === slide
    });
  };

  Wheel.prototype.updateSlide = function(newSlide) {
    if (this.currentSlide != newSlide) {
      this.updateSlideCopy(newSlide);
      this.updateSlideSVG(newSlide);
      this.currentSlide = newSlide;
      this.currentSlideData = this.getCurrentSlideData(this.currentSlide);
    }
  };

  Wheel.prototype.updateSlideCopy = function(newSlide) {
    var prevHalf = this.currentSlideData['height'] / 2;
    var nextHalf = this.getCurrentSlideData(newSlide)['height'] / 2;
    var scrollDistance = prevHalf + nextHalf;

    if (this.currentSlide > newSlide) {
      // backwards
      nextScrollPos = this.currentScrollPos + scrollDistance;
    } else {
      // forwards
      nextScrollPos = this.currentScrollPos - scrollDistance;
    }

    $('.wheel-slider .slide').removeClass('active');
    this.getCurrentSlideData(newSlide)['el'].addClass('active');

    $('.wheel-slider').css({ 'transform': 'translateY(' + nextScrollPos + 'px)' });
    this.currentScrollPos = nextScrollPos;
  };

  Wheel.prototype.updateSlideSVG = function(newSlide) {
    this.hideActiveSVG();
    this.showNextSVG(newSlide);
    this.updateSVGCenter(newSlide);
  };

  Wheel.prototype.hideActiveSVG = function() {
    this.svg.find('#enabled').find('#enabled' + this.currentSlide).hide();
    this.svg.find('#disabled').find('#disabled' + this.currentSlide).show();
  };

  Wheel.prototype.showNextSVG = function(newSlide) {
    this.svg.find('#disabled').find('#disabled' + newSlide).hide();
    this.svg.find('#enabled').find('#enabled' + newSlide).show();
  };

  Wheel.prototype.updateSVGCenter = function(newSlide) {
    var slideData = this.getCurrentSlideData(newSlide);
    this.svg.find('#center [data-name=base] path:first').css({'fill': slideData['svgColor']});
  };

  return Wheel;
})();

function setupAnchorPoints() {
  $('.scroll-points div').each(function() {
    var target = $(this).attr('data-target');
    var $targetEl = $('#' + target);
    var targetHeight = $targetEl.outerHeight(true);
    var targetOffsetTop = $targetEl.offset().top;
    var targetMiddlePos = targetOffsetTop + (targetHeight / 2);
    var anchorPos = targetMiddlePos + scrollAmount;

    if ($(this).attr('data-scroll')) {
      scrollAmount += parseInt($(this).attr('data-scroll'));
    }

    $(this).css({'position': 'absolute', 'top': anchorPos});
  });
}

function updateSlider(current, pos) {
  // update nav
  $('#product-slider .slider-navigation ul li').removeClass('active');
  $('#product-slider .slider-navigation ul li').eq(current).addClass('active');

  // update slide
  $('#product-slider .slider-container .slider').css({'left': pos});
}

function setupSlider() {
  // handle slider animation on scroll
  var sliderTween = new TimelineMax()
  .to("#product-slider", 0.5, {
    onUpdate: function() {
      updateSlider(0, '0%');
    }
  })
  .to("#product-slider", 0.5, {
    onUpdate: function() {
      updateSlider(1, '-100%');
    }
  })
  .to("#product-slider", 0.5, {
    onUpdate: function() {
      updateSlider(2, '-200%');
    }
  })
  .to("#product-slider", 0.5, {
    onUpdate: function() {
      updateSlider(3, '-300%');
    }
  })
  .to("#product-slider", 0.5, {
    onUpdate: function() {
      updateSlider(4, '-400%');
    }
  });

  sliderSection = new ScrollMagic.Scene({
    triggerElement: "#slider-anchor",
    duration: $('#slider-anchor').attr('data-scroll')
  })
  .setPin('#scroll-body')
  .setTween(sliderTween)
  // .addIndicators()
  .addTo(controller);
  // end slider animation
}

function setupWheelSlider() {
  // start wheel slider animation
  var wheelSliderTween = new TimelineMax();
  var wheel = new Wheel();
  wheel.init();

  for (var i = 0; i < $('.wheel-slider .slide').length; i++) {
    wheelSliderTween.to('#product-wheel', 0.5, {
      onUpdate: wheel.updateSlide.bind(wheel),
      onUpdateParams: [i]
    });
  }
  wheelSection = new ScrollMagic.Scene({
    triggerElement: "#wheel-anchor",
    duration: $("#wheel-anchor").attr('data-scroll')
  })
  .setPin('#scroll-body')
  .setTween(wheelSliderTween)
  // .addIndicators()
  .addTo(controller);
  // end wheel slider
}

function addAnimations() {
  if ($(window).width() > 992) {
    var fadeInUp = TweenMax.staggerFromTo('.fade-up', 1,
      {
        y: 50,
        x: -50,
        opacity: 0
      },
      {
        y: 0,
        x: 0,
        opacity: 1
      },
      0.2
    );
    var fadeInUpScene = new ScrollMagic.Scene({
      triggerElement: '.fade-up',
      duration: 300,
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInUp)
    .addTo(controller);

    var fadeSliderUp = TweenMax.staggerFromTo('.fade-slider-up', 1,
      {
        y: 150,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1
      },
      0
    );
    var fadeSliderUpScene = new ScrollMagic.Scene({
      triggerElement: '.fade-slider-up',
      duration: 500,
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeSliderUp)
    .addTo(controller);

    var fadeIn = TweenMax.staggerFromTo('.fade-in', 0.5,
      {
        opacity: 0
      },
      {
        opacity: 1
      },
      0.2
    );
    var fadeInScene = new ScrollMagic.Scene({
      triggerElement: '.fade-in',
      duration: 500,
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeIn)
    .addTo(controller);

    var fadeInRelatedHeading = TweenMax.staggerFromTo('.fade-in-related-heading', 0.5,
      {
        opacity: 0
      },
      {
        opacity: 1
      },
      0.2
    );
    var fadeInRelatedHeadingScene = new ScrollMagic.Scene({
      triggerElement: '.fade-in-related-heading',
      duration: 500,
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInRelatedHeading)
    .addTo(controller);

    var fadeInProductHeading = TweenMax.staggerFromTo('.fade-in-product-heading', 0.5,
      {
        opacity: 0
      },
      {
        opacity: 1
      },
      0.2
    );
    var fadeInProductHeadingScene = new ScrollMagic.Scene({
      triggerElement: '.fade-in-product-heading',
      duration: 300,
      offset: -windowHeightHalf + 200
    })
    .setTween(fadeInProductHeading)
    .addTo(controller);

    var fadeInCTAHeading = TweenMax.staggerFromTo('.fade-in-cta-heading', 0.5,
      {
        opacity: 0
      },
      {
        opacity: 1
      },
      0.2
    );
    var fadeInCTAHeadingScene = new ScrollMagic.Scene({
      triggerElement: '.fade-in-cta-heading',
      duration: 500,
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInCTAHeading)
    .addTo(controller);

    var fadeInRight = TweenMax.staggerFromTo('.fade-right', .5,
      {
        x: '-100%'
      },
      {
        x: '0%'
      },
      0
    );
    var fadeInRightScene = new ScrollMagic.Scene({
      triggerElement: '.fade-right',
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInRight)
    .addTo(controller);

    var fadeInWheelRight = TweenMax.staggerFromTo('.fade-wheel-right', .5,
      {
        x: '-100%'
      },
      {
        x: '0%'
      },
      0
    );
    var fadeInWheelScene = new ScrollMagic.Scene({
      triggerElement: '.fade-wheel-right',
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInWheelRight)
    // .addIndicators()
    .addTo(controller);

    var fadeInContactLeft = TweenMax.staggerFromTo('.fade-contact-left', 1,
      {
        x: '100%'
      },
      {
        x: '0%'
      },
      0
    );
    var fadeInContactScene = new ScrollMagic.Scene({
      triggerElement: '.fade-contact-left',
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInContactLeft)
    .addTo(controller);

    var fadeInLeft = TweenMax.staggerFromTo('.fade-left', .5,
      {
        x: '100%'
      },
      {
        x: '0%'
      },
      0
    );
    var fadeInLeftScene = new ScrollMagic.Scene({
      triggerElement: '.fade-left',
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInLeft)
    .addTo(controller);

    var fadeInSliderLeft = TweenMax.staggerFromTo('.fade-slider-left', .5,
      {
        x: '100%'
      },
      {
        x: '0%'
      },
      0
    );
    var fadeInSliderLeftScene = new ScrollMagic.Scene({
      triggerElement: '.fade-slider-left',
      offset: -windowHeightHalf + 100
    })
    .setTween(fadeInSliderLeft)
    .addTo(controller);

    var zoomIn = TweenMax.staggerFromTo('.zoom-in', 1,
      {
        opacity: 0,
        transform: 'scale(0.8)',
        y: 100
      },
      {
        opacity: 1,
        transform: 'scale(1)',
        y: 0
      },
      0
    );
    var zoomInScene = new ScrollMagic.Scene({
      triggerElement: '.zoom-in',
      duration: 200,
      offset: -windowHeightHalf + 100
    })
    .setTween(zoomIn)
    .addTo(controller);

    var zoomInDelayed = TweenMax.staggerFromTo('.zoom-in-delayed', 1,
      {
        opacity: 0,
        transform: 'scale(0.8)',
        y: 50
      },
      {
        opacity: 1,
        transform: 'scale(1)',
        y: 0
      },
      0
    );
    var zoomInDelayedScene = new ScrollMagic.Scene({
      triggerElement: '.zoom-in-delayed',
      duration: 300,
      offset: -windowHeightHalf + 100
    })
    .setTween(zoomInDelayed)
    .addTo(controller);
  }

  var headerAnimation = new ScrollMagic.Scene({
    offset: 100,
    duration: $('body').height() - ($(window).height() / 2) - 100
  })
  .on('progress', function(event) {
    if (event.scrollDirection == 'FORWARD') {
      $nav.removeClass('fixed');
      $nav.addClass('hide');
      $('.sticky-cta').addClass('active');
    } else {
      $nav.removeClass('hide');
      $nav.addClass('fixed');
    }
  })
  .on('leave', function() {
    $nav.removeClass('fixed');
    $('.sticky-cta').removeClass('active');
  })
  .addTo(controller);
}

// start document ready
$(document).ready(function() {
  $('.related-slider').slick(relatedSliderSettings);

  // handle graph animation
  var graphs = new Graph();
  var graphsInit = graphs.init.bind(graphs);
  var graphSection = new ScrollMagic.Scene({
    triggerElement: "#graph-anchor",
  })
  .on("enter", graphsInit)
  // .addIndicators()
  .addTo(controller);

  if ($(window).width() < 992) {
    // initiate smaller screen sliders
    $('.stat-container').slick(statSliderSettings);
    $('.product-container').slick(packagesSliderSettings);
    $('.wheel-slider').slick(wheelSliderSettings);
    $('.wheel-nav ul').slick(wheelSliderNavSettings);
    $('.slider-container .slider').slick(productSliderSettings);
    $('.slider-navigation ul').slick(productSliderNavSettings);
    $('.slider-navigation ul li').removeClass('active');
    setupAnchorPoints();
  } else {
    setupAnchorPoints();
    setupWheelSlider();
    setupSlider();
  }

  addAnimations();

  $('body').addClass('show-page');
  $nav.removeClass('hide');

  $('.slider-navigation ul li').click(function() {
    if ($('.slider-container .slider').hasClass('slick-initialized')) {
      $('.slider-container .slider').slick('slickGoTo', $(this).attr('data-slick-index'));
    }
    if ($('.slider-navigation ul').hasClass('slick-initialized')) {
      $('.slider-navigation ul').slick('slickGoTo', $(this).attr('data-slick-index'));
    }
  });

  $('.slider-container .slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.slider-navigation ul').slick('slickGoTo', nextSlide);
  });

  $('.wheel-nav ul li').click(function() {
    if ($('.wheel-slider').hasClass('slick-initialized')) {
      $('.wheel-slider').slick('slickGoTo', $(this).attr('data-slick-index'));
    }
    if ($('.wheel-nav ul').hasClass('slick-initialized')) {
      $('.wheel-nav ul').slick('slickGoTo', $(this).attr('data-slick-index'));
    }
  });

  $('.wheel-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.wheel-nav ul').slick('slickGoTo', nextSlide);
  });

  $('.popup-close, .btn-restart').click(function() {
    graphs.restart();
  });

  $('.graph-filter a').click(function() {
    $(this).parent().find('a').removeClass('active');
    $(this).addClass('active');
    graphs.changeGraphs($(this).attr('data-graph'), $(this));
  });

  $('.btn-graph-update').click(function() {
    $('.graph-filter').find('a').removeClass('active');
    $('.graph-filter').find('a[data-graph=' + $(this).attr('data-graph') + ']').addClass('active');
    graphs.changeGraphs($(this).attr('data-graph'), $(this));
  });
  // end graph animation

  $('#contact-form').on('submit', function(e) {
    e.preventDefault();
    var formValid = validateForm();

    if (formValid) {
      submitForm($(this));
    } else {
      $('.form-error').html('Please correct the errors before submitting the form').addClass('active');
    }
  });

  $('header nav a, .btn-anchor').click(function(e) {
    e.preventDefault();
    var target = $(this).attr('href');
    if (target == '#demo') {
      $('.calendly-badge-content').trigger('click');
      return;
    }

    var extra = 0;
    if ($(window).width() > 992) {
      switch (target) {
        case '#product-slider':
          extra = 2000;
          break;
        case '#contact':
          extra = 4000;
          break;
      }
    }
    $('html, body').animate({scrollTop: $(target).position().top + extra}, 1000);
  });

  Calendly.initBadgeWidget({ url: 'https://calendly.com/asl-cloud', text: 'Schedule time with me', color: '#67c4d3', textColor: '#ffffff', branding: true });

  $('.sticky-cta').click(function(e) {
    $('.calendly-badge-content').trigger('click');
  });

  var introVideo = document.getElementById('asl-video');

  function videoPausePlayHandler(e) {
    if (e.type == 'playing') {
      introVideo.removeAttribute("controls");
    } else if (e.type == 'pause') {
      introVideo.setAttribute("controls", "controls");
    }
  }

  introVideo.addEventListener('playing', videoPausePlayHandler, false);
  introVideo.addEventListener('pause', videoPausePlayHandler, false);

});
// end document ready

// start window resize
$(window).on('resize', function() {
  if ($(window).width() < 992) {
    if (!$('.stat-container').hasClass('slick-initialized')) {
      $('.stat-container').slick(statSliderSettings);
    }
    if (!$('.product-container').hasClass('slick-initialized')) {
      $('.product-container').slick(packagesSliderSettings);
    }
    if (!$('.slider-container .slider').hasClass('slick-initialized')) {
      $('.slider-container .slider').slick(productSliderSettings);
    }
    if (!$('.slider-navigation ul').hasClass('slick-initialized')) {
      $('.slider-navigation ul').slick(productSliderNavSettings);
    }
    if (!$('.wheel-slider').hasClass('slick-initialized')) {
      $('.wheel-slider').slick(wheelSliderSettings);
    }
    if (!$('.wheel-nav ul').hasClass('slick-initialized')) {
      $('.wheel-nav ul').slick(wheelSliderNavSettings);
    }
    if (typeof sliderSection !== 'undefined' && sliderSection.enabled()) {
      sliderSection.destroy(true);
      sliderSection.enabled(false);
    }
    if (typeof wheelSection !== 'undefined' && wheelSection.enabled()) {
      wheelSection.destroy(true);
      wheelSection.enabled(false);
    }
  } else {
    scrollAmount = 0;
    setupAnchorPoints();

    if (typeof wheelSection === 'undefined' || !wheelSection.enabled()) {
      setupWheelSlider();
    }
    if (typeof sliderSection === 'undefined' || !sliderSection.enabled()) {
      setupSlider();
    }
  }

});
// end window resize